import { lazy } from "react";
import config from "../config/config.json";

const Home = lazy(() => import("../pages/home/Home"));
const Login = lazy(() => import("../pages/login/Login"));
const RecuperarPass = lazy(() => import("../pages/login/RecuperarPass"));
const CambiarPass = lazy(() => import("../pages/login/CambiarPass"));
const Mobile = lazy(() => import("../pages/mobile/Mobile"));
const SaldoCuenta = lazy(() => import("../pages/saldoCuenta/SaldoCuenta"));
const Miproductos = lazy(() => import("../pages/miProducto/Miproductos"));
const MejorarMembresia = lazy(() =>
  import("../pages/miProducto/MejorarMembresia")
);
const ProductoVendido = lazy(() =>
  import("../pages/productoVendido/ProductoVendido")
);
const Arbol = lazy(() => import("../pages/productoVendido/Arbol"));
const NoFoundExt = lazy(() => import("../components/NotFound/NoFound.js"));
const NotFound = lazy(() => import("../pages/other/NotFound"));
const Maintenance = lazy(() => import("../pages/other/Maintenance"));
const Mapa = lazy(() => import("../pages/mapa/Mapa"));
const MapaCaracteristicas = lazy(() =>
  import("../pages/mapa/MapaPorCaracteristicas.jsx")
);
const Membresias = lazy(() =>
  import("../pages/home/pages/membresias/PlanPagosMembresias")
);
const Planes = lazy(() =>
  import("../pages/home/pages/membresias/PlanPagoPage")
);
const Proyectos = config.PROYECTOS_FILTROS
  ? lazy(() => import("../pages/proyectos/Proyectos.jsx"))
  : lazy(() => import("../pages/home/pages/proyectos/Proyectos"));
const PlanPagosProyectos = lazy(() =>
  import("../pages/home/pages/proyectos/PlanPagosProyectos")
);
const ListaProductoProyecto = lazy(() =>
  import("../pages/home/pages/proyectos/ListaProductoProyecto")
);
const ListaProductosProyectosCaracteristicas = lazy(() =>
  import("../pages/proyectos/ListaProductosProyectosCaracteristicas.jsx")
);
const Usuario = lazy(() => import("../pages/usuario/Usuario"));
const FormPrereserva = lazy(() => import("../pages/formulario/FormPrereserva"));
const FormPrereservaMembresia = lazy(() =>
  import("../pages/prereservaMembresia/PrereservaMembresia")
);
const Notificacion = lazy(() => import("../pages/notificaciones/Notificacion"));
const Detalleproductos = lazy(() =>
  import("../pages/miProducto/DetallesProductos")
);
const DetalleproductosNeizan = lazy(() =>
  import("../pages/miProducto/DetalleProductosNeizan")
);
const RealizarPago = lazy(() => import("../pages/miProducto/RealizarPago"));
const RealizarPagoNeizan = lazy(() =>
  import("../pages/miProducto/RealizarPagoNeizan")
);
const ReconfigurarPlanPago = lazy(() =>
  import("../pages/miProducto/reserva/ReconfigurarPlanPago")
);
const GenerarContrato = lazy(() =>
  import("../pages/miProducto/GenerarContrato")
);
const ImprimirDocumentos = lazy(() =>
  import("../pages/miProducto/compra/ImprimirDocumentos")
);
const TarjetaPrepago = lazy(() =>
  import("../pages/tarjetaPrepago/TarjetaPrepago")
);
const ProyectosHogar = lazy(() => import("../pages/hogar/ProyectosHogar"));
const ProductosProyectoHogar = lazy(() =>
  import("../pages/hogar/ProductosProyectoHogar")
);
const DetalleProductoHogar = lazy(() =>
  import("../pages/hogar/DetalleProductoHogar")
);
const FormPrereservaHogar = lazy(() =>
  import("../pages/hogar/FormPrereservaHogar")
);
const CambiarContrasenha = lazy(() =>
  import("../pages/cambiarPass/CambiarContrasenha")
);
const AtencionCliente = lazy(() => import("../pages/login/AtencionCliente"));
const RankingBonoViaje = lazy(() =>
  import("../pages/home/pages/ranking/RankingBonoViaje")
);
const Capacitacion = lazy(() => import("../pages/capacitacion/Capacitacion"));
const Eventos = lazy(() => import("../pages/eventos/Eventos"));
const EventosACI = lazy(() => import("../pages/eventos-aci/EventosACI"));
const EventosACICrear = lazy(() => import("../pages/eventos-aci/EventosACICrear"));
const EventosACISala = lazy(() => import("../pages/eventos-aci/EventosACISala"));
const EventosACIUnirse = lazy(() => import("../pages/eventos-aci/EventosACIUnirse"));
const EventosACIInvitar = lazy(() => import("../pages/eventos-aci/EventosACIInvitar"));
const EventosACIInvitarModificar = lazy(() => import("../pages/eventos-aci/EventosACIInvitarModificar"));
const EventosACIAdministrar = lazy(() => import("../pages/eventos-aci/EventosACIAdministrar"));
const EventosACIHistorialInvitados = lazy(() => import("../pages/eventos-aci/EventosACIHistorialInvitados"));
const EventosACIHistorialSala = lazy(() => import("../pages/eventos-aci/EventosACIHistorialSala"));
const EventosACIControlAsistencia = lazy(() => import("../pages/eventos-aci/EventosACIControlAsistencia"));
const AbonosRP = lazy(() => import("../pages/abonoRP/AbonosRP"));
const PrereservarAbono = lazy(() =>
  import("../pages/abonoRP/PrereservarAbono")
);
const Tickets = lazy(() => import("../pages/tickets/Tickets"));
const MembresiasCabana = lazy(() => import("../pages/reservasCabana/MembresiasCabanaPage"));
const Hotel = lazy(() => import("../pages/reservasCabana/HotelPage"));
const TicketsCupon = lazy(() => import("../pages/tickets/TicketsCupon"));
const Tramites = lazy(() => import("../pages/tramites/Tramites"));
const CarnetKalomai = lazy(() =>
  import("../pages/carnetKalomai/CarnetKalomaiPage")
);
const bonoMejorVendedor = lazy(() =>
  import("../pages/home/pages/ranking/BonoMejoresVendedores")
);
const MembresiaInternacional = lazy(() => import('../pages/membresiaInternacional/MembresiaInternacional'))
const SuscripcionSion = lazy(() => import('../pages/suscripcionSion/SuscripcionSion.jsx'))
const UpgradeInternacional = lazy(() => import("../pages/membresiaInternacional/UpgradeInternacional"));
const ActivarBeneficio = lazy(() => import("../pages/membresiaInternacional/beneficios/ActivarBeneficio"));
const CalcularParaRecuperar = lazy(() => import("../pages/calcularRecuperacion/CalcularParaRecuperar"));
const Promocion2X1Septiembre = lazy(() => import("../pages/promocion/PromocionSeptiembre2023Page"));
const EventosACIModificarPresentadores = lazy(() => import("../pages/eventos-aci/EventosACIModificarPresentadores"));
const AcreditacionKalomaiPayme = lazy(() => import("../pages/carnetKalomai/paymeAcreditacionKalomai/PaymeAcreditacionKalomai.jsx"));

export default {
  Home,
  Login,
  Mobile,
  SaldoCuenta,
  ImprimirDocumentos,
  Miproductos,
  ProductoVendido,
  Mapa,
  Membresias,
  Proyectos,
  PlanPagosProyectos,
  ReconfigurarPlanPago,
  NotFound,
  NoFoundExt,
  ListaProductoProyecto,
  Usuario,
  Planes,
  FormPrereserva,
  Notificacion,
  Detalleproductos,
  DetalleproductosNeizan,
  RealizarPago,
  RealizarPagoNeizan,
  GenerarContrato,
  ProyectosHogar,
  TarjetaPrepago,
  ProductosProyectoHogar,
  DetalleProductoHogar,
  FormPrereservaHogar,
  RecuperarPass,
  CambiarPass,
  CambiarContrasenha,
  Maintenance,
  ListaProductosProyectosCaracteristicas,
  MapaCaracteristicas,
  AtencionCliente,
  FormPrereservaMembresia,
  MejorarMembresia,
  RankingBonoViaje,
  Capacitacion,
  Eventos,
  EventosACI,
  EventosACICrear,
  EventosACISala,
  EventosACIUnirse,
  EventosACIInvitar,
  EventosACIInvitarModificar,
  EventosACIAdministrar,
  EventosACIHistorialInvitados,
  EventosACIHistorialSala,
  EventosACIControlAsistencia,
  AbonosRP,
  PrereservarAbono,
  Tickets,
  MembresiasCabana,
  Hotel,
  TicketsCupon,
  Tramites,
  CarnetKalomai,
  Arbol,
  bonoMejorVendedor,
  MembresiaInternacional,
  SuscripcionSion,
  UpgradeInternacional,
  ActivarBeneficio,
  CalcularParaRecuperar,
  Promocion2X1Septiembre,
  EventosACIModificarPresentadores,
  AcreditacionKalomaiPayme,
};

export default {
    homePage: {
        eventos: "Eventos",
        capacitacion: "Capacitación",
        ranking: "Ranking",
        verImagen: "Ver imagen",
        verMas: "VER MÁS",
        verMenos: "VER MENOS",
        espanhol: "Español",
        ingles: "Ingles",
        portugues: "Portugués",
        principal: "Principal",
        terrenos: "Terrenos",
        membresias: "Membresías",
        casas: "Casas",
        nuestrosProductos: "Nuestros Productos",
        meInteresa: "ME INTERESA",
        novedades: "Novedades",
        terrenosText:
            "Obtener tu lote, ubicado en la mejor zona de tu ciudad, piensa en tú futuro y la de tus seres queridos, piensa en grande.¡Por que, si yo puedo... tú puedes!.",
        membresiasText:
            'Ahora puedes obtener tu membresía para "KALOMAI PARK", el mejor parque acuático de Bolivia ubicado en el departamento de Santa Cruz de la Sierra.',
        casasText:
            "Obten tu nueva casa, con financiamiento y a un precio increíble en los mejores proyectos urbanísticos ubicados en nuestra ciudad de Santa Cruz de la Sierra.",
        abonosText:
            "Sigue al equipo ROYAL PARI comprando tu abono para toda una gestión. Se parte de esta pasión futbolera.",
        membresiaInternacionalText:
            "Ahora puedes obtener tu membresía KALOMAI TRAVEL para obtener descuentos especiales y semanas completas en cualquier hotel del mundo.",
        ultimasNoticias: "ÚLTIMAS NOTICIAS",
        nuestrasApps: "NUESTRAS APPS",
        empresas: "EMPRESAS",
        empresasSocias: "EMPRESAS SOCIAS",
        direccion: "Dirección Av. Cañoto N673",
        telefono: "Teléfono",
        oficinaVirtual: "Oficina Virtual",
        siguenos: "SIGUENOS",
        pagar: "Mis productos",
        productos: "PRODUCTOS",
        perfil: "PERFIL",
        productosVendidos: "Productos Vendidos",
        liberarReservas: "Liberar Reservas",
        tarjetasPrepago: "Tarjetas Prepago",
        proyectosCasas: "Proyectos Casas",
        antiguoPortal: "Antiguo Portal",
        cerrarSesion: "Cerrar Sesión",
        cambiarPassword: "Cambiar Contraseña",
        verCasas: "Ver Casas",
        verTerrenos: "Ver Terrenos",
        verMembresias: "Ver Membresías",
        abonosRP: "Abonos Royal Pari",
        reservasCabana: "Reservar Cabaña",
        tramite: "Trámites",
        idioma: "Español",
        sesionInactividadTitulo: "Sesión expirada",
        sesionInactividadMensaje: "Su sesión expiro por inactividad.",
        sessionInactividadBoton: "Aceptar",
        miCarnetkalomai: "Acreditación Kalomai",
        descargarCarnet: "Descargar",
        redClientes: "Red Clientes",
        bonoMejoresVendedores: "Bono mejor vendedor",
        membresiaInternacional: "Kalomai Travel",
        suscripSion: "Suscripciones",
        botonPagar: "Pagar",
        eventosACI: "Sala de reuniones"
    },
    proyectosPage: {
        adquiereTuTerreno: "ADQUIERE TU TERRENO",
        verLista: "Ver en lista",
        verEnMapa: "Ver en Mapa",
        verMapa: "Ver Mapa",
        verPlano: "Ver Plano",
        filtros: "Filtros",
        filtrosBusqueda: "Filtros de Búsqueda",
        manzano: "Manzano",
        lote: "Lote",
        producto: "Producto",
        precio: "Precio",
        desc: "Desc",
        precioDesc: "Precio Desc",
        inicial: "Inicial",
        mensual: "Mensual",
        prereservas: "Prereservas",
        prereservar: "Prereservar",
        orden: "Orden",
        plazo: "Plazo",
        estado: "Estado",
        superficie: "Superficie",
        buscar: "Buscar",
        seleccione: "Seleccione",
        precioMasBajo: "Precio más bajo",
        precioMasAlto: "Precio más alto",
        terrenosPequenhos: "Terrenos más pequeños",
        terrenosGrandes: "Terrenos más grandes",
        oro: "Oro",
        contado: "Contado",
        contadoPorcentage: "Contado {{porcentaje}}% de descuento",
        inicialDescuento: "3 años 20% descuento {{inicial}}% cuota inicial",
        todos: "Todos",
        terrenosPrereserva: "Terrenos en prereserva",
        terrenosLibres: "Terrenos Libres",
        filtrar: "Filtrar",
        nroManzano: "Nro Manzano",
        codigoProducto: "CÓDIGO DE PRODUCTO",
        formasCompra: "Formas de Compra",
        verPrereservas: "Ver Prereservas",
        nroPrereserva: "Nro. Prereserva",
        fechaRegistro: "Fecha Registro",
        resultadoFiltroCaracteristicas:
            "Encontrados {{encontrados}} de {{total}} lotes libres",
        resultadoFiltroComplejos: "{{total}} lotes libres",
        nroReserva: "Nro. Reserva",
        nroVenta: "Nro. Venta",
    },
    membresiasPage: {
        contado: "Contado",
        credito: "Descuento",
        descuento: "Descuento",
        precioCDescuento: "Precio C/ Descuento",
        porcentajeInicial: "Porcentaje Inicial",
        plazo: "Plazo",
        cuotaInicial: "Cuota Inicial",
        cuotaMensual: "Cuota Mensual",
        simularContrato: "Simular Contrato",
        simularPlan: "Simular Plan de Pago",
        reservar: "Reservar",
        vista360: "Vista 360",
        anterior: "Anterior",
        simulacionContrato: "Simulación de Contrato",
        simulacionPlanPago: "Simulación de Plan de Pago",
        aceptar: "Aceptar",
        coloqueCi: "Coloque su carnet de identidad",
        ahorro: "Ahorro",
        precioAhora: "Ahora",
        precioAntes: "Antes",
        meses: "Meses",
        inicial: "Inicial",
        montoInicialInvalido: "Debe ingresar un valor valido para poder continuar",
        montoInicialMayorValidacion:
            "El monto ingresado no puede ser mayor o igual a $us ",
        montoInicialMenorValidacion:
            "El monto ingresado no puede ser menor o igual a la cuota inicial de $us ",
        mensajeTooltipInicial:
            "Ahora puedes editar el monto de tu cuota inicial, debe ser mayor al 10% del valor del producto",
        subtitulodiagonalTarjeta: "Oferta",
        nuevaInicial: "Nueva inicial",
        saldoAFinanciar: "Saldo a financiar",
        restriccionesAplican: "Restricciones aplican",
        nuevoInteres: "Interés para ventas nuevas a partir del {{fecha}}",
        nuevaCuotaMensual: "Nueva cuota mensual",
        errorMora: 'El cliente con CI {{ci}} tiene cuotas en mora, solo esta permitido que realicen reservas los clientes que estén al dia.',
        alContado: "Al contado",
        alertDescuento: "Por ser miembro de Grupo Sion tienes un descuento adicional.",
        sinConfiguracion: "No se encontró ninguna configuración",
        primeraCuota: "Primera cuota",
        promocion2x1: "Promocion 2 x 1"
    },
    casasPage: {
        adquiereTuCasa: "Adquiere tu casa",
        codigoProducto: "Código producto",
        interesados: "Interesados",
        precioTotal: "Precio Total",
        verFicha: "Ver Ficha",
        seleccionar: "Seleccionar",
        tituloModelosCasas:
            "En el manzano {{nroManzano}} lote {{nroLote}} se pueden construir los siguientes modelos de casas",
        informacion: "INFORMACIÓN",
        caracteristicas: "Características",
        planDePagos: "PLAN DE PAGOS",
        detalleCuotaInicial: "Detalle de la cuota inicial",
        desgloseCuotaInicial: "Desglose del 3% de la cuota inicial",
        saldoPagar: "Saldo a pagar en 7 días",
        financiamientoDirecto: "Financiamiento directo del 7%",
        cuotasMensuales: "5 cuotas mensuales de",
        detalleFinanciamiento: "Detalle del financiamiento",
        totalVivienda: "Total Vivienda",
        totalAFinanciar: "TOTAL A FINANCIAR",
        cuotaMensualAproximada: "Cuota mensual aproximada",
        nota: "Nota",
        mensajeNota:
            "Los datos de la cuota inicial y cuotas mensuales son referenciales y están sujetos a modificaciones de acuerdo a análisis del banco",
        simulacionPlanPagos: "Simulación de plan de pagos",
        montoFinanciar: "Monto a financiar",
        monto: "Monto",
        continuar: "Continuar",
    },
    pagarPage: {
        prereserva: "PRERESERVA",
        reserva: "RESERVA",
        compra: "COMPRA",
        totalPrecioProducto: "Total precio del producto",
        cuotaMensual: "Cuota mensual",
        expensa: "Expensa",
        pagadoACuenta: "Pagado a cuenta",
        pagarSaldoInicial: "Pagar saldo cuota inicial con",
        validez: "Validez",
        mesesTranscurridos: "Meses transcurridos",
        reconfigurarPlanPagos: "Reconfigurar plan de pagos",
        historicoPagos: "Históricos de pagos",
        comprobante: "Comprobante",
        nombreProducto: "Nombre Producto",
        codigoProducto: "Código Producto",
        nroVenta: "Nro. Venta",
        nroRecibo: "Nro Recibo",
        fechaPago: "Fecha de Pago",
        aCuenta: "A cuenta",
        amortizado: "Amortizado",
        multa: "Multa",
        interes: "Interés",
        totalPagado: "Total Pagado",
        fecha: "Fecha",
        pagaTuReservaCon: "Paga tu reserva con",
        terreno: "Terreno",
        casa: "Casa",
        compras: "Compras",
        totalDeuda: "Total Deuda",
        cuotasVencidas: "Cuotas Vencidas",
        saldoCuotaInicial: "Saldo Cuota Inicial",
        financiamientoDirecto: "Financiamiento Directo",
        saldoFinanciar: "Saldo A Financiar",
        totalCuotaInicial: "Total Cuota Inicial",
        fechaLimite: "Fecha Limite",
        montoRestanteInicial: "Monto Restante A Pagar Inicial",
        fechaContrato: "Fecha Contrato",
        mejorar: "Actualizar",
        mensajePrereservaAbono:
            "Para continuar, debes realizar el pago de tu abono por caja o mediante depósito bancario en cuentas del club Royal Pari.",
        mensajeLimitePagoTarjeta:
            "Por restricciones bancarias, no esta permitido realizar pagos con montos mayores a {{monto}} $us.",
        pagoRestringido:
            "Por motivo de mantenimiento los pagos se podrán realizar a partir del {{fecha}}",
        certificadoCancelacionTotal: "Certificado de Cancelación Total",
        nroBeneficiarios: 'Nro. beneficiarios'
    },
    loginPage: {
        iniciarSesion: "Iniciar sesión",
        usuario: "Usuario",
        password: "Contraseña",
        ingresar: "Ingresar",
        olvideMiPassword: "Olvide mi contraseña",
        verificarCi: "VERIFICAR CI",
        metodoRecuperacion: "MÉTODO DE RECUPERACIÓN",
        finalizar: "FINALIZAR",
        carnetIdentidad: "Carnet de Identidad",
        siguiente: "Siguiente",
        enviarCodigo: "Enviar Código",
        enviarCodigoVerificacion: "Envío de Código de Verificación a:",
        mensajeCodigoEnviado:
            "Se ha enviado un código de verificación a la opción seleccionada.",
        verificar: "Verificar",
        atencion: "Atención",
        mensajeSinCorreo:
            "Usted no cuenta con un correo electrónico, por favor actualizar su información comunicándote con atención al cliente.",
        mensajeCorreoNoValido:
            "El correo electrónico seleccionado no parece ser válido, por favor actualiza tu información apersonandote a atención al cliente o seleccione otra opción de recuperación de contraseña.",
        atencionCliente: "Atención al cliente",
        cerrar: "Cerrar",
        ingresarNuevoPassword: "INGRESE SU NUEVA CONTRASEÑA",
        mensajeReglasPassword:
            "La contraseña debe cumplir con las siguientes características.",
        caracteresMinimos: "Mínimo 8 caractéres",
        almenosMayusculaMinuscula: "Al menos una mayúcula y minúscula",
        caracterEspecial: "Un caracter especial",
        confirmarPassword: "Contraseña debe ser igual a la Confirmar Contraseña",
        errorPassword: "La constraseña no cumple los criterios descritos arriba",
        tituloNuevoPassword: "NUEVA CONTRASEÑA",
        confirmarPasswordLabel: "Confirmar Contraseña",
        mensajeCambioPassword:
            "La nueva contraseña fue actualizada exitósamente. Inicie sesión con sus nuevas credenciales.",
        deAcuerdo: "DE ACUERDO",
        ingresarCodigoVerificacion: "Ingresar Código de Verificación",
        mensajeUsuarioNuevo:
            "Con el objetivo de cuidar tu información debes crear una CONTRASEÑA segura. Se enviará un código de verificación.",
        ingresarAntiguoPortal: "Ir al antiguo portal",
        clickAqui: "Click aquí",
        problemasIngresarPortal:
            "Si tiene algún inconveniente puede volver al Antiguo Portal.",
        contactanos: "Contáctanos",
        modalContactoTitulo: "Estamos para ayudarte",
        modalContactoContenido:
            "Contáctate con atención al cliente para que puedas realizar tus consultas.",
        modalReintentoTitulo: "Atención",
        modalReintentoContenido:
            "Si no recuerdas tu usuario y/o contraseña te recomendamos utilizar la opción de:",
        inicioDeSesion: "Inicio de sesión con",
        huella: "huella",
        preguntaActivarHuella:
            "¿Deseas activar el inicio de sesión con huella en tu cuenta?",
        preguntaActivarFaceId:
            "¿Deseas activar el inicio de sesión con Face ID en tu cuenta?",
        subituloModalHuella:
            "Recuerda que puedes activar o desactivar esta opcion desde tu perfil de usuario.",
        activar: "Activar",
        modalCarnet:
            "Estimado cliente, se le aconseja establecer una contraseña de acceso segura. Tiene un periodo de {{dias}} días para realizar esta acción.",
        modalCarnetUnDia:
            "Estimado cliente, se le aconseja establecer una contraseña de acceso segura. Tiene un periodo de {{dias}} día para realizar esta acción.",
        nuestraApp: "NUESTRA APP",
        textoCodigoEtica: "Acepto el código de ética",
        verCodigoEtica: "Ver",
        errorCodigoEtica: "Debes aceptar el código de ética.",
    },
    notificacionPage: {
        notificaciones: "Notificaciones",
        cargarMas: "Cargar más",
        tituloPaginaNotificacion:
            "Aquí se muestra los mensajes que Grupo Sion ha enviado para ti",
        sinNotificaciones: "Sin notificaciones",
        marcarLeido: "Marcar Leído",
        verMas: "Ver más",
        cargando: "Cargando",
    },
    perfilPage: {
        nombreCompleto: "Nombre Completo",
        ci: "CI",
        telefono: "Teléfono",
        correo: "Correo",
        celular: "Celular",
        direccion: "Dirección",
        mensajeInformacionPerfil:
            "Cualquier cambio de datos en tu información de perfil comunícate con",
        calificacion: "Calificacion: ",
    },
    tarjetaPrepagoPage: {
        campoRequerido: "Este campo es requerido",
        textoCaptcha: "El texto introducido no coincide con el captcha",
        tituloPagina:
            "Ingresa el código de tu tarjeta para consultar tu saldo disponible.",
        mensaje: "Mensaje",
        saldo: "Saldo",
        consultarSaldo: "Consultar Saldo",
        codigoTarjeta: "Código Tarjeta",
        codigoCaptcha: "Código Captcha",
    },
    productosVendidosPage: {
        producto: "Producto",
        cliente: "Cliente",
        telefono: "Telefono",
        email: "Email",
        fecha: "Fecha",
        estado: "Estado",
        comisionable: "Comisionable",
        totalVenta: "Total Venta",
        venta: "Venta",
        prereserva: "PRERESERVA",
        reserva: "RESERVA",
        manzano: "Manzano",
        lote: "Lote",
        superficie: "Superficie",
        precio: "Precio",
        descuento: "Descuento",
        totalPrecioProducto: "Total precio del producto",
        cuotaInicial: "Cuota Inicial",
        cuotaMensual: "Cuota mensual",
        expensa: "Expensa",
        pagado: "Pagado",
        saldo: "Saldo",
        fechaVencimiento: "Fecha Vencimiento",
        nombre: "Nombre",
        ci: "CI",
        liberarPrereserva: "Liberar Prereserva",
        prereservaLiberada: "Preserva Liberada",
        seLiberoPrereserva: "Se libero la prereserva correctamente.",
        motivo: "Motivo",
        campoRequerido: "Este campo es requerido",
        cerrar: "Cerrar",
        cancelar: "Cancelar",
        liberar: "Liberar",
        ventasVacio: "Por el momento, no has realizado ninguna venta.",
        todos: "Todos",
        soloDeudores: "Deudores",
        soloNoDeudores: "No Deudores",
        inicial: "Inicial"
    },
    detallePagoPage: {
        errorMontoValido: "Debe ingresar un monto valido para continuar.",
        errorMontoCero: "El monto a pagar no puede ser cero.",
        errorMontoIncorrecto: "El monto a pagar es incorrecto.",
        errorMontoMayorDeuda:
            "El monto a pagar no puede ser mayor a la deuda total.",
        errorFormatoMonto: "El formato del monto a pagar es incorrecto.",
        mensajeZurielDeshabilitado:
            "Estamos trabajando para brindarle los mejores servicios, muy pronto estaran disponibles nuevas funcionalidades.",
        detalleProductos: "Detalles Productos",
        resumenPago: "RESUMEN DEL PAGO",
        agregarCuotas: "AGREGAR CUOTAS",
        detallePago: "DETALLE PAGO",
        pagar: "PAGAR",
        generarContrato: "GENERAR CONTRATO",
        seleccionarCuotas: "Seleccione las cuotas a agregar",
        cuotaVenceFecha: "CUOTA #{{nroCuota}}, VENCE FECHA:",
        cancelar: "Cancelar",
        agregar: "Agregar",
        precio: "Precio",
        pago: "Pago",
        debe: "Debe",
        cuotasPendientes: "Cuotas Pendientes",
        vencidas: "Vencidas",
        mensual: "Mensual",
        plazo: "Plazo",
        meses: "meses",
        fechaVencimiento: "Fecha de Vencimiento",
        ingreseMontoPagar: "Ingrese el monto a pagar",
        inicial: "INICIAL",
        cuotaInicial: "Cuota Inicial",
        subTotal: "SUBTOTAL",
        pagosACuenta: "PAGOS A CUENTA",
        totalPagar: "TOTAL A PAGAR",
        pagado: "PAGADO",
        detalleCuotas: "Detalle de Cuotas",
        nro: "Nro.",
        vence: "Vence",
        capital: "Capital",
        interes: "Interes",
        formulario: "Formulario",
        expensas: "Expensas",
        multas: "Multas",
        importeCuota: "Importe Cuota",
        pagoCuenta: "Pago a cuenta",
        total: "Total",
        nroCuota: "Nro. Cuota",
        detalles: "Detalles",
        contado: "Contado",
        sinConfiguracionCredito: "SIN CONFIGURACION DE CREDITO ASIGNADO",
    },
    realizarPagoPage: {
        mensaje3ds:
            "Estimado cliente, por temas de seguridad es posible que se realize una transacción para poder validar tu tarjeta, esta acción no incurrirá en ningún costo.",
        mensajePago: "Paga con tus puntos directo desde la aplicación Sion Pay.",
        realizarPago: "Realizar Pago",
        pagoCuotas: "Pago de Cuotas",
        pagoPrereserva: "Pago de Prereserva",
        pagoReserva: "Pago de Reserva",

        pagoCuotasQR: "Pago Simple (QR) - Cuotas",
        pagoPrereservaQR: "Pago Simple (QR) - Prereserva",
        pagoReservaQR: "Pago Simple (QR) - Reserva",

        pagoProcesado: "Pago procesado correctamente",
        pagoExitosoPrereserva:
            "Acabas de realizar el pago de tu prereserva correctamente",
        pagoExitosoCuotas: "Acabas de realizar el pago de tus cuotas correctamente",
        pagoExitosoCuota: "Acabas de realizar el pago de tus cuota correctamente",
        pagoExitosoReserva:
            "Acabas de realizar el pago de tu reserva correctamente",
        volver: "Volver",
        terminosCondiciones: "TÉRMINOS Y CONDICIONES",
        metodoPago: "MÉTODO DE PAGO",
        datosPago: "DATOS DE PAGO",
        finalizar: "FINALIZAR",
        codigoTarjetaRequerido: "El campo Codigo es requerido",
        codigoTarjetaInvalido: "El codigo ingresado es invalido",
        tarjetaPrepago: "Tarjeta Prepago",
        proyecto: "Proyecto",
        producto: "Producto",
        montoPagar: "Monto a Pagar",
        indicaciones: "Indicaciones",
        indicacionCompra:
            'Por favor introduzca los códigos uno por uno, presionando "Cargar" hasta alcanzar el saldo a pagar.',
        indicacionPrereservaReserva:
            'Por favor introduzca el código de su tarjeta y presione "Procesar" para continuar.',
        cargar: "Cargar",
        codigo: "Código",
        codigoTarjeta: "Código Tarjeta",
        montoTarjeta: "Monto Tarjeta",
        montoAplicar: "Monto Aplicar",
        verCuotas: "Ver Cuotas",
        atras: "ATRÁS",
        procesar: "Procesar",
        errorTarjetaRequerida: "El campo Tarjeta es requerido",
        errorVisaMastercard: "Solo esta permitido tarjeta Visa o MasterCard",
        errorTarjetaIncompleta:
            "Debe completar el numero de tarjeta para continuar",
        errorTarjetaIncorrecta: "Debe completar el campo Tarjeta correctamente",
        errorExpiracionRequerido: "El campo Expiracion es requerido",
        errorExpiracionIncorrecto:
            "Debe completar el campo Expiracion correctamente",
        errorMesExpiracion:
            "El Mes introducido en el campo Expiracion es incorrecto",
        errorAnhioExpiracion:
            "El Año introducido en el campo Expiracion es incorrecto",
        errorMesAnhioExpiracion:
            "El Mes y Año introducido en el campo Expiracion es incorrecto",
        errorCvcRequerido: "El campo CVC es requerido",
        errorCvcIncorrecto: "Debe completar el campo CVC correctamente",
        numeroTarjeta: "Número de tarjeta",
        expiracion: "Expiración",
        cuentaSionPayNoCreada: "Su cuenta de SION Pay no esta creada.",
        pagoSionPay: "Pago con SionPay",
        cuenta: "Cuenta",
        estadoCuenta: "Estado de Cuenta",
        puntosDisponibles: "Puntos Disponibles",
        descuento: "Descuento",
        totalPagar: "Total a Pagar",
        ingresarCodigo: "Ingrese su código PIN generado desde su cuenta Sion Pay",
        codigoPin: "Código PIN",
        tituloPagoSionPay: "Datos del Pago - Pagos con puntos SionPay",
        solicitarPagoAmigo: "Solicitar a un amigo que pague la cuota",
        generarCodigo: "GENERAR CÓDIGO",
        generarCodigoMensaje:
            "Genera un código para que tu amigo realize el pago desde la aplicación",
        copiar: "COPIAR",
        volverAPagar: "Volver a Pagar",
        copiadoPortapapeles: "Copiado al portapapeles correctamente.",
        habilitado: "Habilitado",
        datosDeSuCuenta: "Datos de su cuenta",
        tituloAlertTarjeta: "Lista de tarjetas",
        tituloInfoTarjeta: "Lista de tarjetas",
        mensajeAdvertenciaTarjeta:
            "Estimado cliente usted a excedido el límite de tarjetas permitidas en Sion Pay o Sion Office. Comunicarse con atención al cliente para proceder a la habilitación.",
        terminoCondiciones: "Términos y Condiciones",
        aceptar: "Aceptar",
        aceptarTerminos: "Acepto los términos y condiciones",
        deshabilitarPagoTarjetaMensaje:
            "Tambien puedes realizar tu pago por: VENTANILLAS SION, DEPÓSITOS Y/O TRANSFERENCIAS, SION PAY",
        nuevaTarjeta: "Nueva tarjeta",
        sinTarjetas: "No tienes tarjetas registradas.",
        vencida: "Vencida",
        habilitada: "Habilitada",
        noHabilitada: "No habilitada",
        eliminar: "Eliminar",
        seleccionar: "Seleccionar",
        habilitar: "Habilitar",
        habilitarTarjeta: "Habilitar tarjeta",
        cancelar: "Cancelar",
        eliminarTarjeta: "Eliminar tarjeta",
        mensajeEliminarTarjeta: "¿Estas seguro/a de eliminar la tarjeta {{card}}?",
        pagar: "Pagar",
        detallePago: "Detalle de pago",
        prereserva: "PRERESERVA",
        reserva: "RESERVA",
        compra: "CUOTAS",
        codigoCvc: "Código de verificación (CVC)",
        upgradeMembresia: "UPGRADE DE MEMBRESIA",
        tituloNoPosible: "El pago por tarjeta no está disponible",
        mensajeNoPosible: "Para montos mayores a {{monto}} el pago por tarjeta no está disponible.",
        pagoQR: "Realizar el pago por QR",
        criptoactivo: "En calidad de cliente del Grupo SION y en cumplimiento a normativa vigente, declaro que los fondos de la presente transacción no tienen origen, motivo o destino en operaciones de compra o venta de criptoactivos",
    },
    generarContratoPage: {
        generarContrato: "Generar Contrato",
        datosPersonales: "DATOS PERSONALES",
        tituloContrato: "TÍTULO CONTRATO",
        planPagos: "PLAN DE PAGOS",
        campoRequerido: "Este campo es requerido",
        emailIncorrecto: "El campo Email tiene formato incorrecto",
        docIdentidad: "Doc. Identidad",
        nit: "Nit",
        nacionalidad: "Nacionalidad",
        documentoExpedido: "Documento Expedido",
        fechaNacimiento: "Fecha Nacimiento",
        primerNombre: "Primer Nombre",
        segundoNombre: "Segundo Nombre",
        apellidoPaterno: "Apellido Paterno",
        apellidoMaterno: "Apellido Materno",
        direccion: "Dirección",
        paisResidencia: "País Residencia",
        ciudadResidencia: "Ciudad Residencia",
        genero: "Género",
        telefono: "Teléfono",
        celular: "Celular",
        correo: "Correo",
        referido: "Referido",
        siguiente: "SIGUIENTE",
        atras: "ATRAS",
        fechaInicio: "Fecha Inicio",
        fechaPago: "Fecha Pago",
        nro: "Nro",
        saldoCapital: "Saldo Capital",
        cuota: "Cuota",
        formulario: "Formulario",
        saldo: "Saldo",
        importeCuota: "Importe Cuota",
        expensa: "Expensa",
        saldoExpensa: "Saldo Expensa",
        aceptarContrato: "Aceptar Contrato",
    },
    formPrereservaPage: {
        formularioPrereserva: "Formulario Prereserva",
        errorPrereservar: "No puede prereservar",
        errorRegistrarPrereserva:
            "Error al registrar prereserva revise los datos por favor",
        datosDelProducto: "Datos del producto",
        cuotaInicial: "Cuota Inicial",
        producto: "Producto",
        datosDelCliente: "Datos del cliente",
        documentoIdentidad: "Documento Identidad",
        nacionalidad: "Nacionalidad",
        expedidoEn: "Expedido en",
        extranjero: "EXTRANJERO",
        fechaNacimiento: "Fecha Nacimiento",
        nombre1: "Nombre 1",
        nombre2: "Nombre 2",
        apellidoPaterno: "Apellido paterno",
        apellidoMaterno: "Apellido materno",
        paisResidencia: "Pais de residencia",
        ciudadResidencia: "Ciudad residencia",
        genero: "Genero",
        masculino: "Masculino",
        femenino: "Femenino",
        telefono: "Telefono",
        celular: "Celular",
        correo: "Correo",
        direccionCliente: "Dirección del cliente",
        direccion: "Direccion",
        cancelar: "Cancelar",
        reservar: "Reservar",
        advertencia: "ADVERTENCIA",
        prereservaLote: "Prereserva Lote",
        prereservaMembresia: "Prereserva Membresia",
        prereservaCasa: "Prereserva Casa",
        mensajePrereservaCasa: "Se registro la prereserva correctamente",
        nombre1Requerido: "Nombre 1 Requerido",
        primerNombreRequerido: "Primer nombre requerido",
        apPaternoVacio:
            "Este no usuario no tiene registrado el apellido , por favor actualice sus datos",
        appPaternoMaternoRequerido: "Apellido paterno o materno Requerido",
        direccionRequerido: "Dirección es requerido",
        direccionNumerosLetras: "El campo Dirección solo permite números y letras.",
        celularRequerido: "Celular es requerido",
        telefonoRequerido: "Teléfono es requerido",
        soloNumeros: "Solo se admiten numeros",
        minimoDigitos: "Al menos se requiere {{nroDigitos}} digitos",
        errorSinCorreo: "No tiene correo asignado por favor actualice sus datos",
        correoRequerido: "Correo es requerido",
        correoInvalido: "Formato de correo invalido",
        ciudadResidenciaRequerido: "Ciudad residencia es requerido",
        nacionalidadRequerido: "Nacionalidad es requerida",
        fechaNacimientoRequerido: "Fecha nacimiento es requerido",
        generoRequerido: "Genero es requerido",
        paisResidenciaRequerido: "Pais residencia es requerido",
        ciRequerido: "CI Expedido es requerido",
        idTipoDocIdentidadRequerido: "Tipo de documento es requerido",
        fechaNacimientoMayorEdad:
            "La prereserva solamente es para mayores de 18 años.",
        tipoDocumento: "Tipo de documento",
        incial: "Inicial",
        mensual2: "Mensual",
    },
    cambiarPasswordPage: {
        cambiarPassword: "Cambiar Contraseña",
        reglasPasswordTitulo:
            "La contraseña debe cumplir con las siguientes características.",
        reglaMinimo: "Mínimo 8 caractéres",
        reglaMayusculas: "Al menos una mayúcula y minúscula",
        reglaCaracteresEspeciales: "Un caracter especial",
        reglaRepetirPassword:
            "Confirmar Contraseña debe ser igual a la Nueva Contraseña",
        errorPassword: "La constraseña no cumple los criterios descritos arriba",
        anteriorPassword: "Anterior Contraseña",
        nuevoPassword: "Nueva Contraseña",
        confirmarPassword: "Confirmar Contraseña",
        errorConfirmarPassword:
            "Confirmar Contraseña debe ser igual a la Nueva Contraseña",
        cambiar: "Cambiar",
        tituloModal: "NUEVA CONTRASEÑA",
        mensajeModal:
            "La nueva contraseña fue registrada exitósamente. La proxima vez que inicies sesión debes ingresar tu nueva contraseña.",
        deAcuerdo: "DE ACUERDO",
    },
    mapaPage: {
        errorCargarProyecto:
            "Ah ocurrido un problema al cargar el proyecto intente mas tarde",
        tamanho: "Tamaño",
        mapaProyecto: "Mapa Proyecto",
        mapaDeProyecto: "Mapa de Proyecto",
        dimension: "Dimensión",
        estadoTerrenos: "Estado de los terrenos",
        libre: "LIBRE",
        vendido: "VENDIDO",
        noFiltrado: "NO FILTRADO",
        cargarProyecto: "Cargar Proyecto",
        caracteristicasProyecto:
            "Este proyecto cuenta con las siguientes caracteristicas",
        leyenda: "Leyenda",
    },
    mantenimientoPage: {
        enMantenimiento: "EN MANTENIMIENTO",
        volveremosEn: "VOLVEREMOS EN",
        dias: "Días",
        horas: "Horas",
        minutos: "Minutos",
        segundos: "Segundos",
        volverAIntentar: "Volver a intentar",
        expiraEn: "Expira en",
    },
    reducer: {
        sinAccesoInternet: "Sin acceso a internet",
        errorCargarDatos:
            "Error al cargar los datos inténtelo mas tarde, por favor",
        error444: "Error 444 elio",
        sesionExpiro: "Su sesion expiro",
        expiroPusher: "Exipiro sesion pusher",
        expiroMovil: "Su sesion expiro solo valido para movil",
        expiroApi: "Sesion expiro mediante la api",
        intenteloMasTarde: "Inténtelo mas tarde, por favor",
        cambiarIdiomaMsg:
            "Se relizo el cambio de idioma correctamente, por favor vuelve a iniciar sesion para continuar.",
        cambioIdioma: "Cambio de Idioma",
        registrarBroker: "Registrar Bróker",
        brokerLabel: "Bróker",
        brokerHelperText: "Ingrese el carnet de identidad o nombre del bróker.",
        brokerRegistroExitoso: "Se registro al bróker correctamente.",
        brokerDescripcion:
            "Bróker es aquella persona que colaboró en concretar la venta. Recibirá un incentivo de acuerdo a políticas de la empresa.",
    },
    InfoTarjetaLinkser: {},
    MensajeConfirmacion: {
        botonAceptar: "De acuerdo",
        tituloModal: "Atención",
    },
    AlertTarjeta: {
        descripcionExcedida:
            "Hemos detectado que ya tienes {{nroTarjetas}} tarjetas registradas",
        checkDescripcion: "Pagar con otra tarjeta que no esté en el listado.",
        botonConfirmacion: "Entiendo",
        botonAtras: "Atrás",
    },
    modalProjectsPorFiltros: {
        tipoComplejoAbierto: "ABIERTA",
        tipoComplejoCerrado: "CERRADA",
        realizarBusqueda: "Realiza tu búsqueda",
        seleccionarZona: "Selecciona una zona",
        seleccionarTipoComplejo: "Selecciona un tipo de urbanización",
        cuotaInicial: "Cuota inicial en $us",
        cuotaMensual: "Cuota mensual en $us",
        seleccionarComplejo: "Selecciona tu complejo",
        zona: "Zona:",
        tipoComplejo: "Tipo complejo:",
        btnBuscar: "BUSCAR",
        filtroCaracteristicas: "Por características",
        filtroComplejo: "Por nombre",
        lotesLibres: "Lotes libres:",
    },
    prereservaMembresia: {
        meses: "meses",
        pagoInicialDe: "Pago Inicial de",
        cuotaMensualDe: "Cuota Mensual de",
        resumen: "RESUMEN",
        pagoAlContado: "Pago al contado",
        tituloModalVacation: "Deseas agregar una Membresía Kalomai Resort?",
        agregarMembresiaVacation: "Agregar Membresía Kalomai Resort",
        seleccionarPlan: "Seleccionar plan",
        eliminarTitulo: "¿Estas seguro?",
        eliminarMensaje:
            "¿Estas seguro de eliminar esta membresía de tu compra actual?",
        prereservasRealizadas: "Prereservas Realizadas",
        errorPrereserva: "Una o mas prereservas no pudieron realizarse",
        successPrereserva:
            "Las Prereservas de las membresías seleccionadas se registraron correctamente.",
        mes: "mes",
    },
    mejorarPage: {
        costoAdenda: "Costo del upgrade",
        abonado: "Abonado",
        verAdenda: "Ver Adenda",
        conceptoPago: "Concepto de Pago",
        actualizacionMembresia: "Actualización de membresía",
        mejorarMembresia: "Mejorar Membresía",
        modalOkTitulo: "Membresía mejorada correctamente",
        modalOkMensaje:
            "Tu membresía {{origen}} fue mejorada correctamente. Ahora puedes disfrutar de tu nueva membresía: {{destino}}",
        modalWarnTitulo: "Membresía mejorada correctamente",
        modalWarnMensaje:
            "Se mejoró la membresia Kalomai correctamente, por favor pasar por Atención al Cliente para complementar datos necesarios para la Adenda.",
        modalAdendaCheck:
            "He revisado correctamente mis datos personales en la ADENDA y acepto que la información es correcta.",
        modalAdendaMessage:
            "En caso de que alguno de sus datos personales sea incorrecto por favor comunícate con atención al cliente.",
        adenda: "Adenda",
        aceptarAdenda: "Aceptar Adenda",
        mensajeOro: "Su plan de pagos no tendrá ninguna modificación.",
        saldoPagar: "Saldo a Pagar",
        aceptarDocumentos: "Aceptar Documentos",
        renuncia: "ACEPTACIÓN DE CONDICIONES",
        documentos: "DOCUMENTOS",
        modalRenunciaCheck:
            "Estoy de acuerdo al ACEPTAR LAS CONDICIONES, asimismo corroboro que mi informacion personal es correcta.",
        verDocumentos: "VER DOCUMENTOS",
    },
    rankingPage: {
        puntos: "Puntos: ",
        ventasUno: "¡Tienes",
        ventasDos: "venta(s), te faltan ",
        ventasTres: " más!",
        ventasTotal: " ventas !",
        sinVentaFuera: "¡No te quedes fuera!",
        sinVentaCarrera:
            "Con tan solo 1 venta estaras en la carrera por tus puntos",
        veneficiario:
            "Serán beneficiados los 40 mejores vendedores del semestre que acumulen la mayor cantidad de puntos",
        notasAclaratorias: "NOTAS ACLARATORIAS",
        notaUno:
            "Si el ACI es antiguo debe realizar 1 venta como mínimo, desde el inicio de la carrera hasta el cierre del semestre.",
        notaDos:
            "Nota: Se considera ACI antiguo a aquella persona que hubiera realizado una o más ventas hasta un día antes del inicio de la carrera.",
        notaTres:
            "Si el ACI es nuevo, ingresa a la carrera desde que realiza su primera venta, a partir de ese mes debe estar activo y vender todos los meses hasta que concluya el semestre",
        notaCuatro:
            "Nota: Se considera ACI nuevo a aquella persona que realice su primera venta dentro del semestre en el que la carrera haya comenzado.",
        notaCinco:
            "Si el ACI nuevo o antiguo deja de vender un mes, al mes siguiente debe realizar si o si dos ventas para mantenerse en carrera caso contrario queda fuera de la misma. Nota: esta opción solo puede ser ocupada una vez, es decir si el ACI por segunda vez dejade vender un mes consecutivo queda automáticamente fuera de la carrera.",
        notaSeis:
            "El ACI ganador debe estar vigente en las cuotas mensuales de todos sus productos personales hasta el momento del viaje.",
        notaSiete:
            "Los productos de las ventas, que hacen acreedor del bono de viaje al ACI, deben estar al día mínimamente con las 2 primeras cuotas mensuales para garantizar su puntaje. Caso contrario la venta no será tomada en cuenta para la acumulación de puntos de la carrera.",
        notaOcho:
            "Las cuotas mensuales de los productos que se vendieron fuera del rango de fecha de la carrera no suman puntos para el bono viaje.",
        notaNueve:
            "Todas las cuotas mensuales pagadas (solo capital), hasta la fecha de la última revisión genera puntos.",
        notaDiez:
            "Hasta la fecha final de revisión, las amortizaciones realizadas generaran puntos.",
        notaOnce:
            "Se realizarán dos carreras al año para este bono, los mismos estarán contemplados de la siguiente manera:",
        notaDoce:
            "Si una de las ventas que suman puntos para la carrera realiza Cambio de Patrocinio o Cesión de derecho no se tomará en cuenta todo el puntaje de esa venta.",
        notaTrece:
            "Si una de las ventas realizadas en el semestre de cada carrera se revierte antes de la fecha indicada de la última revisión, no será tomada en cuenta para el puntaje del bono viaje, pero el ACI es considerado activo ese mes en la revisión del bono viaje.",
        notaCatorce:
            "Si el ACI está con código inactivo, bloqueado o con proceso de ética al momento de realizar el viaje, perderá el Bono de Viaje.",
        notaQuince: "Cada Un dólar pagado equivale a 1 punto",
        notaDieciseis:
            "El pago de las multas y recargos, NO genera puntos para la carrera.",
        notaDieciSiete:
            "Las ventas al contado sumarán puntos, como si fuera una venta a plazos con el 10% de cuota inicial y sus dos mensualidades mínimas pagadas.",
        notaSiesiOcho: "Son válidas las auto compras.",
        notaDieciNueve:
            "El paquete y rutas del viaje dependerá del destino y la agencia (excursiones, juegos, souvenirs, etc. corren por cuenta del ganador).",
        notaVeinte: "El bono de viaje es INTRANSFERIBLE.",
        thSemestre: "Semestre",
        thInicioCarrera: "Inicio carrera",
        thFinalCarrera: "Final carrera",
        thFechaUltimaRevision: "Fecha ultima revision",
        thMesViaje: "Mes del viaje",
        porDefinir: "por definir",
        preCalificado: "Pre - calificado",
    },
    contadorRegresivo: {
        quedan: "Quedan:",
        dias: "Dia(s)",
        horas: "Hora(s)",
        minutos: "Minutos(s)",
        segundos: "Segundo(s)",
    },
    portadaRanking: {
        ganaPuntos: "¡Gana puntos en todas tus ventas!",
        desde: "Desde el",
        al: "al",
    },
    eventos: {
        validarNombreLetras:
            'El campo "Nombre completo" solo debe contener letras.',
        validacionIdEventoRequerido: 'El campo "Evento" es obligatorio',
        validacionNombreInvitadoRequerido:
            'El campo "Nombre completo" es obligatorio.',
        validacionNombreInvitadoMax:
            'El campo "Nombre completo" no puede tener más de 255 caracteres.',
        validacionCiInvitadoRequerido: 'El campo "Carnet" es obligatorio.',
        valicacionCiInvitadoMax:
            'El campo "Carnet" no puede tener más de 40 caracteres.',
        validacionFechaNacimiento: 'El campo "Fecha de nacimiento" es incorrecto.',
        validacionFechaNacimientoRequerido:
            'El campo "Fecha de nacimiento" es obligatorio.',
        invitadoRegistrado: "Se registró al invitado correctamente.",
        listaInvitadosVacia: "En este momento no existen eventos habilitados.",
        evento: "Evento",
        todos: "TODOS",
        agregar: "Agregar invitado",
        nombre: "Nombre",
        carnet: "Carnet",
        fechaNacimiento: "Fecha de nacimiento",
        registrarInvitado: "Registrar invitado",
        seleccioneEvento: "SELECCIONE UN EVENTO",
        nombreCompleto: "Nombre completo",
        cancelar: "Cancelar",
        registrar: "Registrar",
    },
    abonos: {
        errorStock:
            "En este momento no existe stock disponible para el Abono seleccionado.",
        guerreroRoyalPari: "GUERRERO ROYAL PARI",
        abono: "ABONO",
        precios: "Precios",
        beneficios: "Beneficios",
        familiar: "FAMILIAR",
        guerrero: "SUPER GUERRERO",
        especial: "ESPECIAL",
        infantil: "INFANTIL",
        mensajeAlertPrereserva: "Seleccione un precio para realizar la prereserva.",
        tituloModal: "Prereservar abono",
        mensajeModal: "Se realizo la prereserva correctamente",
        beneficio1: "Pertenecer al grupo de whatsapp de socios.",
        beneficio2: "Descuentos de entradas y viajes con el equipo.",
        beneficio3: "Kits de bienvenida.",
        beneficio4: "Descuentos de nuestros productos merchandising.",
        beneficio5: "Experiencia profesional con el equipo.",
        beneficio6: "Entrada a los entrenamientos del equipo.",
        beneficio7: "10% de descuento SPA para la familia en la Clínica Urbarí.",
        beneficio8: "5% descuento para internación en la Clínica Urbarí.",
        beneficio9:
            "Ingreso al estadio cuando somos locales a todos los partidos oficiales de la div. profesional o copa sudamericana.",
        beneficio10: "10 pases a Kalomai Park al año.",
        beneficio11: "5 pases a Kalomai Park al año.",
        contado: "CONTADO",
        credito: "CRÉDITO",
        nroPrereserva: "Su número de prereserva es:",
    },
    ticketsPage: {
        tickets: "Tickets Pago Puntual",
        cupon: "CUPONES",
        tituloPrincipal: "Tickets Pago Puntual ",
        ticketscupon: "Cupones",
        tituloPrincipalcupon: "MIS CUPONES",
        tituloPaginaSb1: "PROMOCION PAGO PUNTUAL.",
        tituloPaginaSb2:
            "Paga puntual tu cuota de terreno del mes de Octubre 2023 y recibes 2 ticket.",
        tituloPaginaSb1cp: "PROMOCION PAGO PUNTUAL.",
        tituloPaginaSb2cp:
            "Paga puntual tu cuota de membresía y recibes un tickets Virtual de consumo para uso en Kalomai.",
        fechaini: "válido desde: ",
        fechafin: "válido hasta: ",
        valido: "VIGENTE",
        expirado: "EXPIRADO ",
        agotado: "AGOTADO ",
        pendiente: "PENDIENTE ",
        informacion: "NOTA: ",
        informacion2: "Para ingresar al parque KALOMAI debe presentar el Ticket.",
        informacion2cupon: "Los cupones deben ser canjeados en el parque.",
    },

    updateDataUser: {
        titulo: "Actualización de datos",
        subTitulo:
            "Por favor es necesario la actualización de los siguientes datos",
        labelCorreo: "Correo",
        errorCorreo: "El correo no es válido",
        correoRequerido: "El correo es requerido",
        labelPais: " País de residencia",
        textItemPais: "Selecciona su país de residencia",
        seleccionarPais: "Debe seleccionar su país de residencia",
        labelCiudad: "Ciudad de residencia",
        textItemCiudad: "Selecciona ciudad de residencia",
        seleccionarCiudad: "Debe seleccionar su ciudad de residencia",
        labelCodigoPais: "Código país",
        idioma: "es",
        labelTelefono: "Celular",
        telefonoNoNumero: "El celular solo deben ser números",
        telefonoRequerido: "El número de celular es requerido",
        telefonoMinDigitos:
            "El celular no debe tener menos de {{minDigitos}} dígitos",
        telefonoMaxDigitos:
            "El celular no debe tener más de {{maxDigitos}} dígitos",
        btnActualizar: "Actualizar datos",
        textActualizacionCorrecta: "Sus datos han sido actualizados correctamente",
        btnAceptar: "Aceptar",
        textLoader: "Actualizando datos",
    },
    consultaCiDialog: {
        textYo: "Yo",
        textHacerReserva: "Realizaré una reserva para",
    },
    datosFactura: {
        titulo: "Datos para la factura",
        msgInformativo:
            "Verifique que sus datos sean correctos para evitar futuros reclamos.",
        labelDocumento: "Número de documento",
        errorDocumentoNoNumero: "El tipo de documento acepta solo números",
        documentoMinDigitos: "Número de documento mínimo {{minDigitos}} caracteres",
        documentoMaxDigitos: "Número de documento máximo {{maxDigitos}} caracteres",
        errorDocumento: "El número de documento es requerido",
        labelComplemento: "Complemento",
        errorCompAlfaNum: "Error, ej.: 1A",
        errorComplemento: "Max. {{maxDigitos}} caract",
        labelTipoDocumento: "Tipo de documento",
        textItemTipoDoc: "Selecciona tipo de documento",
        errorTipoDocumento: "Debe seleccionar el tipo de documento",
        labelRazonSocial: "Razon social",
        razonSocialRequerido: "La razon social es requerido",
        labelCorreo: "Correo",
        errorCorreo: "El correo no es válido",
        correoRequerido: "El correo es requerido",
        labelTelefono: "Celular",
        telefonoNoNumero: "El celular solo deben ser números",
        telefonoRequerido: "El número de celular es requerido",
        telefonoMinDigitos:
            "El celular no debe tener menos de {{minDigitos}} dígitos",
        telefonoMaxDigitos:
            "El celular no debe tener más de {{maxDigitos}} dígitos",
        btnTextEnviar: "Guardar datos",
        textBtnVerDatos: "Ver mis datos para la factura",
        textBtnCancelar: "Cancelar",
        textDatosRegistrados: "Sus datos han sido registrados correctamente",
        btnAceptar: "Aceptar",
    },
    simpleQR: {
        titulo: "Simple QR",
        textDescargar: "Descargar",
        textCompartir: "Compartir",
        descripcion:
            "Para ver otras formas de pago haga clic en el siguiente boton",
        textFormasPago: "Otras formas de pago",
        pagoQR: "Pago simple QR",
        errorQR: "No se pudo generar el QR, Puede ver otras formas de pago",
        pagoConfirmado: "Su pago por QR fue registrado correctamente",
        btnAceptar: "Aceptar",
        textErrorAlRegistrar:
            "El pago por QR fue confirmado, se encuentra en proceso de regularización",
        nroTransaccion: "Nro. transacción:",
        fechaVencimiento: "Fecha de vencimiento:",
        monto: "Monto:",
        conceptoPago: "Concepto: Pago",
        tituloQR: "PAGAR POR QR",
        noGenerado: "No se pudo generar el código QR",
        reintentar: "Volver a intentar",
        cerrar: "Cerrar QR",
    },
    carnetKalomaiPage: {
        tituloAlertSinKarnet: "ACREDITACIÓN KALOMAI",
        cuerpoAlertSinKarnet:
            "Si tienes una membresia de Kalomai, comunicate con atencion al cliente para regularizar tu ACREDITACIÓN KALOMAI.",
    },
    membresiasCabanasPage: {
        titulo: "Mis membresías",
        subtitulo: "Selecciona la membresía con la cual accederás a la reserva de tu cabaña.",
        mensajeEnMora: "Estimada cliente, tiene cuotas vencidas de pago, ponte al día para acceder a los beneficios de reservar una cabaña.",
        mensajeMembresiasVacias: "No cuenta con membresías para reservar una cabaña.",
        mensajeNoTieneCarnetVigente: "Estimado cliente, no cuenta con carnet de identidad vigente, comuníquese con atención al cliente para actualizar sus datos.",
        labelProducto: "Producto: "
    },
    portadaBonoMejorVendedor: {
        ganaPuntos: "¡Bono mejor vendedor!",
        desde: "Desde el",
        al: "al",
        validezCicloBono: "Del 18 De febrero al 31 de julio 2023",
    },
    bonoMejorVendedorPage: {
        puntos: "Puntos: ",
        ventasUno: "¡Tienes",
        ventasDos: "venta(s), te faltan ",
        ventasTres: " más!",
        ventasTotal: " ventas !",
        sinVentaFuera: "¡No te quedes fuera!",
        sinVentaCarrera:
            "Concreta tus ventas, se parte de los 30 mejores del Mes y gana el BONO de 300$",
        veneficiario:
            "Serán beneficiarios todos los asesores comerciales independientes que alcancen la meta en monto de ventas de membresía y/o terrenos comprendidos en las siguientes fechas:",
        politicas: "POLITICAS DE LA FORMA DE GANANCIA BONO MEJORES VENDEDORES",
        mejoresVendedores:
            "A los 30 mejores vendedores en monto de cada mes se les dará 300$ en efectivo",
        notasAclaratorias: "Notas Aclaratorias:",
        notaUno:
            "Para acceder al bono el ACI tendrá que estar entre los mejores vendedores en monto del mes.",
        notaDos:
            "El asesor debe estar al día en sus productos personales para recibir el bono.",
        notaTres: "Son válidas solamente las ventas personales.",
        notaCuatro:
            "Solo se tomarán en cuenta las ventas con contrato generado hasta el cierre de cada mes.",
        notaCinco:
            "El bono será entregado cada mes junto con el pago de comisiones.",
        notaSeis: "Son Válidas las ventas a plazos y contado.",
        notaSiete:
            "Para recibir el bono el ACI debe facturar caso contrario se le hará la retención de ley correspondiente.",
        notaOcho:
            "El listado final está sujeto a verificación y políticas de la empresa.",
        thSemestre: "Semestre",
        thInicioCarrera: "Inicio carrera",
        thFinalCarrera: "Final carrera",
        thFechaUltimaRevision: "Fecha ultima revision",
        thMesViaje: "Mes del viaje",
        porDefinir: "por definir",
        posicionPreliminar: "Tu posición actual",
        condicionesGenerales: "CONDICIONES GENERALES",
        condicionGeneralUno:
            "Sólo serán tomadas en cuenta para la campaña las ventas con contrato generado dentro de las fechas establecidas para el incentivo.",
        condicionGeneralDos:
            "Los ACIs que sean ganadores del incentivo, no deben tener código inactivo, bloqueado, expulsado o con proceso de ética al momento de recibir el incentivo, caso contrario lo perderán.",
        condicionGeneralTres:
            "Si una de las ventas de los clientes de la campaña del incentivo tiene cesión de derecho, cambio de patrocinador, reprogramación recisión/ resolución de contrato o traspaso de capital no será contabilizada para el bono.",
        monto: "Monto: ",
        posicionActual: "TU POSICIÓN ACTUAL ES:",
        tienes: "¡TIENES",
        enVenta: "$us. EN VENTA!",
        debesEstar: "DEBES ESTAR ENTRE LOS 30 MEJORES PARA RECIBIR EL BONO",
    },
    upgradeTravel: {
        precioTotal: "Precio Total",
        totalPagado: "Total Pagado",
        totalDeuda: "Total Deuda",
        cuotasVencidas: "Cuotas Vencidas",
        precio: "Precio",
        descuento: "Descuento",
        precioFinal: "Precio Final",
        cuotaInicial: "Cuota Inicial",
        saldo: "Saldo",
        totalFinanciar: "Total a Financiar",
        cuotaMensual: "Cuota Mensual",
        plazo: "Plazo",
        simularAdenda: "Simular Adenda",
        btnAtras: "Atrás",
        btnPagarInicial: "Pagar cuota inicial",
        agotado: "AGOTADO",
        textMensaje: "Esté pendiente a nuevas habilitaciones",
        btnVolverAtras: "Volver a mis productos",
        txtPagoRealizado: "Su pago se realizó correctamente.",
        txtVerNuevoProducto: "Ya puede ver su nuevo producto.",
        btnMisProductos: "Mis productos",
        detalleUpgrade: "DETALLE UPGRADE",
        pagarUpgrade: "PAGAR UPGRADE",
        labelPorcentaje: "Porcentaje Inicial",
        textAlContado: "Al contado",
        btnAlContado: "Pagar al contado",
        textMeses: "Meses",
        tituloModalTravel: "UPGRADE MEMBRESIA TRAVEL",
        verTravel: "Upgrade Travel",
    },
    beneficioTravel: {
        btnActivarBeneficio: "Activar beneficio",
        precio: "Precio",
        pagado: "Pagado",
        deuda: "Deuda",
        cuotasVencidas: "Cuotas Vencidas",
        btnVerBeneficio: "Ver mis beneficios",
        tituloBeneficio: "Beneficios Disponibles",
        beneficios: "Beneficios",
        btnAdquirir: "Adquirir beneficio",
        noHabilitadoTitulo: "No esta habilitado",
        noHabilitadoMensaje: "Para habilitar el beneficio debe tener pagado un {{porcentaje}}% de su producto y no tener cuotas pendientes de pago",
        cerrar: "Cerrar",
        tituloMiBeneficio: "Mis Beneficios Adquiridos",
        detalle: "Detalle Beneficio",
        codigo: "Código RCI",
        fechaVencimiento: "Fecha de Vencimiento",
        mensajeConfirmacion: "Ya puede ver su nuevo beneficio.",
        cerrarPago: "Cerrar Pagos",
        pagoBeneficio: "Pago Beneficio",
        tipoPago: "Tipo de pago",
        verMas: 'Ver Mas',
        verMenos: 'Ver Menos',
    },
    calcularRecuperacion: {
        textoMenu: "Calcular Recuperación",
        calculadora: "Calculadora de recuperación",
        textPlaceholder: "Buscar por C.I.",
        nombre: "Nombre",
        ci: "C.I.",
        nroVenta: "Nro. Venta",
        producto: "Producto",
        fechaContrato: "Fecha Contrato",
        totalPagado: "Total Pagado",
        totalDeuda: "Total Deuda",
        montoReconocido: "Monto Reconocido",
        btnBuscarProyectos: "Buscar Proyectos",
        proyectos: "Proyectos",
        productos: "Productos",
        btnCerrar: "Cerrar",
        mensajeAlert: "No se encontró ningún proyecto",
        precioLista: "Precio Lista",
        descuento: "Descuento",
        precioVenta: "Precio Venta",
        saldo: "Saldo a Financiar",
        restaurar: "Restaurar valores",
        sinReversiones: "No tiene productos revertidos",
        tituloDialogComparar: "Producto Revertido / Nuevo Producto",
    },
    pagoStripe: {
        tituloModal: 'Pago realizado',
        procesando: 'procesando',
        pagar: 'pagar',
        pagoMensaje: 'El pago de su beneficio se registro exitosamente.',
        pagofallido: 'Su pago esta siendo procesado.',
        mailInvalido: 'Seleccione un correo electrónico válido',
        mailConfirma: 'Confirmar correo electrónico',
        correoElectronico: 'Correo electrónico',
        mensajeCorreo: 'Recibirás la información de tu pago, al correo electrónico seleccionado.',
        selectTextMail: 'Correo electrónico',
        selectMailVacio: 'Seleccione un correo electrónico '
    },
    eventosACI: {
        etiquetaCrearPaso1: "Nombre del evento, ciudad y plazas",
        etiquetaCrearPaso2: "Fecha , hora y dirección",
        etiquetaCrearPaso3: "Organizador , presentador y telefono de contacto",
        etiquetaCrearPaso4: "Segundo presentador",
        dirrecionTitulo: "Dirección del evento.",
        nombreTitulo: "Nombre del evento.",
        ciudadTitulo: "Ciudad.",
        nombreOrganizadorTitulo: "Nombre del organizador.",
        celularOrganizadorTitulo: "Celular del organizador",
        disertanteTitulo: "Nombre del presentador",
        fechaHora: "Fecha y Hora",
        capacidadTitulo: "Plazas disponibles",
        tituloDatos: "Datos generales del evento.",
        tituloDatosDirreccion: "Datos de la organización",
        tituloDatosOrganizador: "Datos de la presentación",
        tituloDatosDisertante: "(Opcional). Segundo presentador",
        disertanteBuscar: 'Buscar presentador',
        tituloBuscarCI: "Buscar por CI",
        placeHolderDisertante: "Ingresa el CI de un ACI",
        duracionLabel: "Duración en horas",
        dialogCreateTitle: "Nueva sala de reuniones",
        dialogCreateOK: "Si, Crear evento",
        dialogCreateCancel: "No",
        dialogDisertanteTitle: "Confimar presentador.",
        dialogDisertanteOk: "Si, confirmar presentador!",
        dialogInfoSearchNotFound: "No se encontro ningun presentador",
        dialogInfoSearchAgain: "Por favor realiza una nueva busqueda!",
        dialogErrorMessage: "Error!",
        dialogInvitarOk: "Si, Invitar",
        dialogInvitarCancel: "No, lo hare luego",
        dialogInvitarTitle: "Invitar prospecto",
        homeTituloMisEventos: "Mis Próximos eventos.",
        campoDireccion: 'Lugar',
        campoCiudad: 'Ciudad',
        campoFechaHora: 'Fecha y Hora',
        campoDuraccion: 'Duración ',
        campoCapacidad: 'Capacidad',
        campoOrganizador: 'Organizador',
        campoDisertante: 'Presentador',
        campoTelefono: 'Celular',
        campoEmail: 'Correo electrónico',
        campoNombre: 'Nombre completo',
        campoInvitadoCI: 'Número de celula de identidad',
        tituloInvitados: 'Lista de invitados',
        tablaInvitadoNumero: 'N',
        tablaInvitadoNombre: 'Nombre',
        tablaInvitadoEmail: 'Email',
        tablaInvitadoTelefono: 'Telefono',
        tablaInvitadoFechaInvitacion: "Fecha de invitacion",
        tablaInvitadoPrePatrocinador: 'Pre patrocinador',
        tablaInvitadoCiudad: 'Ciudad',
        tablaInvitadoEvento: 'Evento',
        tablaInvitadoAcciones: 'Acciones',
        nuevoInvitado: 'Invitar a un prospecto',
        invitar: 'Invitar',
        salaEventoTitulo: 'Sala de eventos',
        salaAdministrar: 'Modificar Evento',
        invitarTo: 'Invitar a ',
        eventos: 'Eventos',
        organizarTitulo: 'Organizar un evento ACI',
        unirseSala: 'Unirme a un evento',
        crearEvento: 'Crear evento',
        unirseEventoSubHeading: 'Únete a un evento cercano a ti. Primero selecciona una ciudad',
        eventosNoRooms: 'No hay eventos disponibles en esta ciudad.',
        proximosEventos: 'Próximos eventos:',
        unirmeEvento: 'Unirme a este evento',
        salaHeading: 'Cierra más ventas participando en eventos',
        unirmeEventoSucces: 'Felicidades te uniste al evento',
        crearEventoPropio: 'Crear mi propio evento',
        unirmeto: 'Unirme a:',
        dialogUnirmeOk: 'Si, unirme.',
        dialogUnirmeCancel: 'No, lo hare luego',
        dialogJoinedSuccess: 'Felicidades te uniste al evento :',
        btnSala: 'Ver Sala',
        landingEventosHeading: 'Eventos Asesor Comercial Independiente.',
        landingEventosSubHeading: 'Cierra más ventas participando de eventos. Organiza tu propio evento o lleva tus prospectos a otro evento.',
        langdingCrearHeading: 'Organiza tu propio evento, invita a otros ACI, prospectos y cierra más ventas.',
        estadoEventoCancelado: 'Cancelado',
        estadoEventoFinalizado: 'Finalizado',
        estadoEventoEncurso: 'En curso',
        estadoEventoProgramado: 'Programado',
        salaAdministrarTituloFecha: "Modificar fecha y duración",
        salaAdministrarTituloDireccion: "Modificar ciudad y dirección",
        salaAdministrarTituloGeneral: "Modificar modelo evento y capacidad",
        salaAdministrarTituloDisertante: "Modificar presentador",
        dialogModificar: "Si, modificar",
        dialogModificarTitleFrom: "Modificar titulo de",
        dialogModificarTo: "a",
        accionModificar: "Modificar",
        accionConjuccion: "y",
        dialogModificarCapacidadFrom: "Modificar capacidad de",
        dialogSinCambios: "He, no has realizado ningun cambio!",
        ciudadActual: "Ciudad actual",
        dialogModificarCiudadFrom: "Modificar ciudad",
        dialogModificarDireccionFrom: "Modificar dirección",
        dialogModificarFechaFrom: "Modificar fecha",
        dialogModificarDuraccionFrom: "Modificar duración",
        dialogModificarDisertanteFrom: "Modificar presentador",
        dialogModificarEventoSuccess: "Evento modificado exitosamente!",
        dialogConfirmarDisertante: "Confirmar a",
        accionNo: "No",
        accionCancel: "Si, cancelar",
        btnCancelar: "Cancelar evento",
        btnRetirar: "Retirar invitación",
        btnCrearEvento: "Crear evento",
        btnValidar: "Validar",
        btnRegresar: "Regresar",
        habilitarCancelar: "Habilitar cancelar",
        dialogCancelarTitulo: "Cancelar Evento",
        dialogCancelarSuccess: "Evento Cancelado",
        cargandoEvento: "Cargando datos del evento",
        autoInvitacionError: "No puedes invitarte a ti mismo",
        disertanteComoprospecto: "El presentador no puede ser un prospecto",
        asdisertante: "Como presentador?",
        asCapacidad: "con capacidad",
        volverInicio: "Volver el inicio",
        presionaParaFinalizar: "Listo, presiona crear evento para finalizar",
        verInvitados: "Ver lista de invitados",
        infoStaff: "Datos de los organizadores",
        creacionExitosa: "Evento programado exitosamente",
        historialHeading: "Historial",
        historialSubheading: "Tu historico de creación, participación en salas e historico de invitados",
        historialEventos: "Historial Eventos",
        historialEventosSubheading: "Tu historico de creación, participación en salas de eventos",
        historialInvitados: "Historial invitados",
        historialInvitadosSubheading: "Historico de tus invitaciones a prospectos",
        tablaInvitadoDias: "Dias transcurridos",
        tablaInvitadoEstado: "Estado",
        estadoInvitacionAbierta: "Registrada",
        estadoInvitacionVencida: "Vencida",
        estadoInvitacionConvertida: "Convertida",
        estadoInvitacionRetirada: "Retirada",
        estadoInvitacionIndeterminado: "Indeterminado",
        estadoInvitaconNodefinido: "No definido",
        administrarInvitacion: "Modificar invitación",
        enviarInvitacion: "Invitar prospecto",
        dialogModificarInvitacionOk: "Si, modificar",
        dialogModificarProspecto: "Modificar prospecto",
        dialogRetirarProspecto: "Retirar prospecto",
        checBoxRetirarInvitacion: "Habilitar retirar invitación",
        dialogRetirarInvitacionOk: "Si, retirar invitación",
        dialogAbandonarSala: "Abandonar Sala",
        dialogAbandonarSalaWarning: "Si abandonas la sala todas tus invitaciones a prospecto en esta sala se retiraran",
        dialogAbandonarSalaAction: "Si, Abandonar Sala",
        dialogAbandonarSalaSuccess: "Abandonaste la Sala",
        salaBtnControlAsistencia: "Control de asistencia",
        tituloPaginaControlAsistencia: "Control de asisencia",
        paginaControlAsistenciaSubheading: "Verifica la asistencia del staff, ACI y prospectos",
        leyendaAsistencia: "Los prospectos de otro ACI seran visibles 60 minutos antes de comenzar el evento",
        btnConfirmarAsitencia: "Confirmar asistencia",
        btnNoAsistio: "No, asistio",
        dialogConfirmarAsistencia: "Confirmar asistencia",
        dialogConfirmarAsistenciaSub: "Seleciona una invitacion",
        tituloProspectoDatosContacto: "Datos de contacto",
        textoAsistencia: "Asistio",
        textoNoAsistencia: "No asistio",
        textoNoDefinado: "",
        prospectoContactoCorreo: "Solo correo",
        prospectoContactoDual: "Correo y teléfono",
        prospectoContactoTelefono: "Solo teléfono",
        aclaracion2Disertante: "Puede ser el creador de la sala o otro ACI",
        btnVerificar: "Verificar",
        moderardorSala: "Es Moderador de sala",
        moderardorSalaSubHeading: "El moderardor pude ayudar en el control de asistencia",
        moderadorDialogTitle: "Modificar permisos de moderador",
        moderadorDialogOk: "Modificar permisos",
        moderadorDialogContent: "Modificar permisos",
        moderadorDialogContentDone: "Se modificaron los permisos",
        btnOmitir: "Omitir este paso",
        TituloAdministrarSala: "Administrar Sala",
        segundoDisertante: 'Segundo presentador',
        eliminarSegundoDisertante: 'Remover al segundo presentador',
        errorDireccionNula: "La direccion es nula o vacia",
        errorDireccionLongitudCorta: "La direccion es demasiada corta",
        errorDireccionLongitudLargo: "La direccion es demasiada larga",
        errorFechaFueraRango: "Fecha fuera de rango",
        errorDuracionFueraRango: "Duraccion fuera de rango",
        errorIngresaUnCIValido: "Ingresa un ci valido",
        errorDisertanteYOrganizador: "El presentador y el organizador no pueden ser la misma persona",
        errorModeloEvento: "Selecciona una modelo de evento",
        errorSeleccionCiudad: "Selecciona una ciudad por favor",
        warningCapacidad: "La capacidad mínima de un evento es 5 y la máxima 999",
        errorAgenda1Part1: "No puedes programar un evento a esta hora por que el presentador ya tiene agendado",
        errorAgenda1Part2: "con fecha",
        errorAgenda1Part3: "y duración",
        errorAgenda1Part4: ", selecciona otro presentador",
        errorAgenda2Part1: "El segundo presentador ya tiene un evento agendadado ",
        errorAgenda2Part2: "con fecha",
        errorAgenda2Part3: "y duración",
        errorAgenda2Part4: ", selecciona otro presentador",
        errorNoACI: "El CI no pertenece a un ACI",
        errorProspectoCorreo: "Ingrese la dirección de correo electrónico del cliente potencial",
        errorAbandonoSala: "Error al intentar salir de la sala",
        errorFormatoCI: "Formato incorrecto para ID de persona",
        errorFormatoCorreo: "Formato incorrecto para la dirección de correo electrónico",
        errorProspectoCelular: "Tienes que proporcionar el número de teléfono",
        errorFormatoCelular: "Número de teléfono incorrecto",
        datosProspecto: "Datos de contacto de prospectos",
        errorRazonCancelar: "Debe proporcionar una descripción para continuar",
        errorCalendarioCreador: "No se puede programar la reunión {{nombre}} porque ya tiene una agenda de reunión a las {{fechaHora}} con una duración de {{duracion}} hrs",
        errorCalendarioDisertante: "No se puede programar la reunión {{nombre}} porque el orador principal tiene una agenda de reunión a las {{fechaHora}} con una duración de {{duracion}} hrs",
        errorCalendarioDisertante2: "No se puede programar la reunión {{nombre}} porque el orador auxiliar tiene una agenda de reunión a las {{fechaHora}} con una duración de {{duracion}} hrs",
        errorFormatoNombre: "Longitud de nombre incorrecta. La longitud del nombre debe tener entre 5 y 120 caracteres",
        mensajeSalaAsignarDisertante: "Asignar : {{nombre}} como presentador?",
        btnOk: "Ok",
        textoNuevoDisertante: "Nuevo presentador",
        mensajeAsistenciaConfirmada: "¡Confirmación de asistencia al evento!",
        mensajeFaltaConfirmada: "No se presentó",
        mensajeModificacionPermisos: "Se modificaron los permisos de {{nombre}}",
        mensajeFiltrarNombre: "Ingresa un criterio de busqueda",
        mensajeRetirarProspecto: "{{nombre}} identificado por id {{ci}} retirado con éxito",
        mensajeModificarProspecto: "{{nombre}} identificado por id {{ci}} modificado correctamente",
        btnSiguiente: "Siguiente",
        btnContinuar: "Continuar",
        successProspectoInvitacion: "La persona {{nombre}} identificada con id {{ci}} creada como Lead exitosamente",
        campoID: "CI",
        errorInicialFecha: "La reunión de salas debe programarse con 12 horas de anticipación. Seleccionar otra fecha",
        crearEventoDialogo: "¿Te gustaría crear un evento de Negocios,¿{{nombre}} para asesores de negocios independientes (ACI) para {{capacidad}} invitados?",
        documentoIDPlaceHolder: "Ingrese su documento nacional de identidad",
        documentoIDLabel: "Documento de identidad nacional o ID"
    },
    promocion2X1Septiembre: {
        selecciona2doProd: "Selecciona 2º Producto",
        complejo: "Complejo",
        producto: "Producto",
        manzano: "Manzano",
        nroLote: "Nro. Lote",
        superficie: "Superficie",
        productoSeleccionado: "Producto seleccionado",
        seleccionandoProducto: "Seleccionando el producto:",
    },
    datosUif: {
        otrosDatos: "OTROS DATOS",
        ciudadNacimiento: "Ciudad Nacimiento",
        profesion: "Profesion",
        actividadComercial: "Actividad comercial",
        funcionarioPublico: "¿Es actualmente o ha sido funcionario Público en los últimos 5 años?",
        nombreInstitucionPublica: "Nombre Institucion Publica",
        cargo: "Cargo",
        declaroInfoVerazFide: "Declaro como cliente que la información personal proporcionada es veraz y fidedigna.",
    },
    rooms: {
        noEligle: "Lo sentimos, no cumples con todos los requisitos necesarios para acceder a esta funcionalidad.",
        nroSala: "Nro de sala",
        enlaceCopiar: "Copiar enlace tú de invitación ",
        enlaceCopiado: "¡Link copiado al portapapeles!",
        freelancers: "Freelancers",
        invitados: "Convidados",
        titulo: "Sala Nro #{{id}}",
        tipoSala: "Tipo de reunión",
        searchText: "Filtrar por nombre",
        hideChecked: "Ocultar revisados",
        filtros: "Filtros",
        buscar: "Buscar",
        fecha1: "Desde",
        fecha2: "Hasta",
        noPersonMessage: "No invitaciones para mostrar",
        errorCruceHorario: "La persona con documento id {{id}}, tiene cruce de horario con el evento {{name}} con fecha {{date}} y duración {{length}}",
        fecha: "Fecha",
        hora: "Hora"
    },
    membershipCorporatiomn: {
        cantidadBeneficiarios: 'Cantidad de beneficiados',
        beneficiarios: 'Beneficiarios',
        requeridoCinco: 'Minimo {{nro}} personas',
        personas: 'Personas',
        beneficiario: 'Beneficiario',
        validTextFamiliar: 'Min 5, max 12 Beneficiarios',
        validTextCoporation: 'Min 5, max 999 Beneficiarios'
    },
    paymeUpgradeQr:{
        tituloPaymeUpgrade:"PAGO QR - UPGRADE KALOMAI",
        cuerpoAlertSucces:
            "Al completar el pago recibirás un correo en {{correo}}. Para continuar con el upgrade o realizar cambios en tu correo, comunícate con atención al cliente",
    },
    paymeAcreditacionQr: {
        tituloButtonPaymeAcreditacion: "PAGO QR - ACREDITACION KALOMAI",
        cuerpoAlertSucces:
            "Al completar el pago recibirás un correo en {{correo}}. Para continuar con la acreditación o realizar cambios en tu correo, comunícate con atención al cliente",
        cuerpoAlertWarining:
            "Comunícate con atencion al cliente para regular tu Acreditación Kalomai.",
        titulo: "PAGO DE ACREDITACIÓN KALOMAI - QR",
        membresia: "Membresía",
        codigo: "Código",
        precioUnitario: "Precio Unitario",
        cantidadBeneficiarios: "Beneficiarios",
        precioTotal: "Precio Total",
        monedaDePago: "Moneda de Pago",
        productoNoHabilitado: " Su producto no está habilitado para el pago de acreditación.",
        qrEnEspera: " QR generado aquí",
        descargarQr: "Descargar QR",
        tituloButtonGenerarQr: "Generar Qr",
        buttonCardProductPaymeAcred: "Pagar acreditación",
    },
    metodosPagoBene: {
        selectMetodo:'Seleccione un metodo de pago',
        pagoTarjeta:"Pago con tarjeta"
    }
};
